/*Linear Gauge*/
.linear-gauge {
    width: 300px;
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 20px auto;
    padding: 10px;
}

.gauge-header {
    background: #007bff;
    color: white;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    font-size: 1.2em;
}

.gauge-value {
    font-size: 2.5em;
    font-weight: 700;
    margin: 10px 0;
}

.gauge-scale {
    position: relative;
    height: 20px;
    background: linear-gradient(to right, red, yellow, green, cyan, blue, purple, indigo);
    border-radius: 5px;
    margin: 10px 0;
}

.gauge-fill {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.gauge-marker {
    position: absolute;
    top: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
}

.gauge-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 0.9em;
}
