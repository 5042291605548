.background {
	position: relative;
	background-image: url("../../assets/login-background.jpg");
	background-size: cover;
	background-position: center;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	overflow: hidden; /* Para garantir que os elementos filhos não escapem do contêiner */
}

.background::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3); /* Preto com 50% de opacidade */
	z-index: 1; /* Coloca a camada preta abaixo do conteúdo */
}

.background > * {
	position: relative;
	z-index: 2; /* Garante que o conteúdo fique acima da camada preta */
}

.container {
	border: 3px solid #dbe0e3;
	border-radius: 10px;
	display: flex;
	min-width: 20%;
	width: 700px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(131, 185, 195, 0.528);
}

.exatta-logo {
	margin: 20px 0px 20px 0px;
	height: auto;
	width: 300px;
}

.credencials-container {
	display: flex;
	position: relative;
	width: 90%;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border-width: 0px;
	border: none;
	margin: 10px;
	background-color: rgba(255, 255, 255, 0.63);
}

.login-input {
	width: 100%;
	height: 30px;
	border-radius: 10px;
	border-width: 0px;
	border: none;
	padding-left: 15px;
	font-weight: bold;
	color: rgb(95, 95, 95);
}

.password-button {
	position: absolute;
	right: 10px;
	border-radius: 10px;
	border: none;
	outline: none;
	background-color: rgba(255, 255, 255, 0.63);
	color: rgb(95, 95, 95);
}

.loading-login,
.buttonText {
	width: 90%;
	display: flex;
	justify-content: center;
	border: none;
	border-radius: 10px;
	background-color: #4472c4;
	color: white;
	font-weight: bold;
	text-align: center;
	padding: 10px 0px 10px 0px;
	margin: 10px 0px 20px 0px;
}

.password-button:hover,
.buttonText:hover {
	cursor: pointer;
}

.error-container {
	width: 90%;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #f6c7cb;
	background-color: #f8d7da;
	color: #922e3f;
	font-size: 14px;
	margin: 10px 0px 10px 0px;
}

.a-help {
	width: 90%;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background-color: #dbe0e3;
	font-size: 14px;
	margin: 0px 0px 20px 0px;
	text-decoration: none;
}

.help-container {
	font-size: 14px;
	text-decoration: none;
	color: var(--main-color);
	font-weight: bold;
}
