.show-basic-data-container {
  display: flex;
  align-items: center;
}

.status-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.status-indicator.acionado {
  background-color: green;
}

.status-indicator.desacionado {
  background-color: red;
}
