.progress-container {
	position: relative;
	width: 90%;
	background-color: #b9b5b5;
	border-radius: 10px;
	overflow: hidden;
	margin: 10px;
}

.progress-bar {
	height: 30px;
	background-color: #4caf50;
	line-height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: width 0.5s ease-in-out;
}

.progress-percentage {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-weight: bold;
	text-align: center;
}

.little-panel-container {
	justify-content: center;
}

.show-basic-data-container {
	display: flex;
	height: 30px;
	justify-content: center;
	align-items: center;
	padding: 10px;
}
