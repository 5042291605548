.little-panel-container,
.big-panel-container,
.cultivation-data-panel,
.alerts-panel-container {
  position: relative;
  border-radius: 10px;
  background-color: rgb(217, 218, 217);
}

.big-panel-container {
  width: 90%;
}

.panel-title-container {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgb(40, 73, 92);
}

.panel-title {
  color: white;
  text-align: center;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
}

.panel-content-padding {
  padding: 10px;
}

.panel-footer-container {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
}

.panel-footer-text {
  font-size: 11px;
}

.panel-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

/* Paineparagraph-stylel de Condições Climáticas Horárias */
.weather-data-container,
.item-data-container {
  display: flex;
  align-items: center;
  margin: 0px 15px 0px 15px;
}

.weather-data-container-second {
  margin-left: 20px;
}

.weather-icon {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.last-value-text {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.paragraph-style {
  text-align: center;
  font-size: 14px;
}

/* Painel de Dados da Cultura */
.cultivation-data-panel {
  margin-right: 10px;
  width: 28%;
}

.cultivation-data-text {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

/* Painel de Alertas */
.alerts-panel-container {
  width: 37%;
}

.alerts-panel-content-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.alerts-panel-available-water-content-container {
  display: flex;
  margin-bottom: 10px;
}

.alerts-panel-title {
  color: white;
  padding: 5px;
  font-size: 12px;
  text-align: center;
}

.alerts-aw-panel-content,
.alerts-panel-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alerts-panel-content {
  margin: 10px;
}

.alerts-aw-panel-content {
  align-items: center;
}

.alerts-container {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: white;
}

.alerts-title-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #345494;
  justify-content: center;
  display: flex;
}

.alerts-aw-lb-panel-div {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.one-value-panel-text {
  font-size: 25px;
  font-weight: bold;
  color: white;
  margin-top: 10px;
}

/* Painel de Água Disponível no Solo */
.ad-panel-content-container {
  display: flex;
  margin-bottom: 5px;
}

.ad-panel-values-container {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ad-panel-20-value-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.ad-panel-values-text {
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.ad-panel-content-container-align {
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
}

.ad-panel-first-column-size {
  display: flex;
  width: 25px;
  height: 100%;
  border-radius: 25px;
}

.ad-panel-first-column-color {
  display: flex;
  flex: 1;
  background-color: #e6e7e9;
  border-radius: 25px;
}

.ad-panel-second-column-color {
  flex: 1;
  display: flex;
  align-items: flex-end;
  background-color: #c7c8da;
  border-radius: 25px;
  margin: 5px;
}

/* Painel de Tempo de Irrigação */
.it-content-container,
.last-data-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 10px 0px;
}

.it-text-style {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

/* Paineis Animal */
.last-data-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.panel-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.panel-items-title {
  font-weight: bolder;
  font-size: 14px;
}

.more-details-button {
  border: none;
  background: transparent;
  color: var(--main-color);
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 700px) {
  .panel-title {
    font-size: 14px;
  }

  .panel-title-container {
    height: 35px;
  }

  .paragraph-style {
    font-size: 14px;
    text-align: center;
  }

  .it-text-style,
  .last-value-text {
    font-size: 14px;
  }

  .cultivation-data-panel,
  .alerts-panel-container {
    width: 90%;
    margin: 10px 0px;
  }

  .alerts-panel-available-water-content-container {
    flex-direction: column;
  }

  .alerts-aw-lb-panel-div {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  
}
